import { Pager } from "src/components/Pager/Pager"
import RewardsEmptyImage from "src/components/ReferralProgram/ReferralHistory/images/rewards-empty-state.svg"
import { ReferralHistoryEmpty } from "src/components/ReferralProgram/ReferralHistory/ReferralHistoryEmpty"
import { useFetchReferralProgramRewards } from "src/data/referralProgram/queries/referralProgramQueries"
import { TReferralsProgramRewardsResponse } from "src/data/referralProgram/types/referralProgramQueryTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { useSearchParams } from "src/router/useSearchParams"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MBadge, MBadgeProps } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { ErrorService } from "src/utils/ErrorService"

export function ReferralHistoryRewards() {
  const { searchParams, setSearchParams } = useSearchParams({
    keys: [
      {
        key: "rewards_offset",
        type: "number",
      },
    ],
  })

  const fetchRewards = useFetchReferralProgramRewards({
    filter: {
      offset: searchParams.rewards_offset ?? undefined,
    },
  })

  const { t, langKeys } = useTranslate()

  const headers = [
    <div key="reward">{t(langKeys.referral_program_rewards)}</div>,
    <div key="status">{t(langKeys.status)}</div>,
  ]

  const rows =
    fetchRewards.data?.rewards.map((reward) => (
      <>
        <div>
          <MText>{reward.name}</MText>
        </div>
        <div>
          <div>
            <MBadge color={getStatusBadgeColor(reward.status)}>
              {getStatusLabel({
                t,
                status: reward.status,
              })}
            </MBadge>
          </div>
        </div>
      </>
    )) ?? []

  if (!fetchRewards.isLoading && fetchRewards.data?.rewards.length === 0) {
    return <ReferralHistoryEmpty Image={RewardsEmptyImage} />
  }

  return (
    <div>
      <GridTable
        header={headers}
        rows={rows}
        mobileRows={rows}
        minWidth={0}
        templateColumns="minmax(300px, 1fr) minmax(300px, 1fr)"
        horizontalScroll
        loading={fetchRewards.isLoading}
      />
      <Pager
        offset={searchParams.rewards_offset ?? 0}
        setOffset={(offset) => setSearchParams("rewards_offset", offset)}
        limit={50}
        totalCount={fetchRewards.data?.paging.total_count}
      />
    </div>
  )
}

function getStatusBadgeColor(
  status: TReferralsProgramRewardsResponse["rewards"][number]["status"]
): MBadgeProps["color"] {
  if (status === "confirmed") {
    return "good"
  }

  if (status === "pending") {
    return "warning"
  }

  return "error"
}

function getStatusLabel({
  t,
  status,
}: {
  t: TTranslateFunction
  status: TReferralsProgramRewardsResponse["rewards"][number]["status"]
}) {
  switch (status) {
    case "confirmed":
      return t(langKeys.sent)
    case "cancelled":
      return t(langKeys.cancelled)
    case "pending":
      return t(langKeys.on_hold)
    case "unknown":
      return t(langKeys.unknown)
    default:
      ErrorService.captureException(
        new Error(`[REFERRAL PROGRAM] Unknown reward status label: ${status}`)
      )
      return t(langKeys.unknown)
  }
}
